import React from 'react'
import { Link, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import { Footer } from '../components/footer'
import styled from 'styled-components'
import Svg from './svg/NewsLogo.svg'

const LayoutCatalog = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: end;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 30px 1rem;

  .about_wrapper {
    width: 100%;
  }

  h1 {
    font-family: 'GT Walsheim Pro Bold';
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  .about_images {
    margin-bottom: 10vw;
  }
`

const Card = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  border-radius: 8px;
  height: 200px;
  margin: 30px 0;

  img {
    width: 50%;
  }

  &:hover {
    background-color: #fafafa;
  }

  .first {
    display: flex;
    justify-content: center;
  }
  .second {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    color: #000;
    margin-right: 15px;
  }
  .second-head {
    font-family: 'GT Walsheim Pro Bold';
    padding-bottom: 15px;
  }
`

class VolunteerNews extends React.Component {
  render() {
    const news = this?.props?.data?.allContentfulNews?.edges

    return (
      <Layout location="/volunteer-news">
        <LayoutCatalog>
          <Helmet title="Новости проекта" />
          <div className="about_wrapper">
            <h1>Новости проекта</h1>
            {news.map(({ node }) => {
              return (
                <Link to={`/news/${node.slug}`}>
                  <Card>
                    <div className="first">
                      <img src={Svg} alt="" />
                    </div>
                    <div className="second">
                      <span className="second-head">{node.title}</span>
                    </div>
                  </Card>
                </Link>
              )
            })}
          </div>
        </LayoutCatalog>
        <Footer
          text="При финансовой поддержке Российского фонда культуры в рамках проекта № 21-3-000455 «Волонтёрская деятельность в сфере сохранения и популяризации культурного наследия: лучшие практики и инициативы»"
        />
      </Layout>
    )
  }
}

export default VolunteerNews

export const pageQuery = graphql`
  query VolutneerNewsQuery {
    allContentfulNews(
      filter: {
        slug: {
          in: [
            "29082021alushta"
            "20092021"
            "17092021"
            "20092021"
            "1314102021"
            "20102021"
            "1725102021"
            "27102021"
            "27102021-2"
          ]
        }
      }
    ) {
      edges {
        node {
          slug
          title
        }
      }
    }
  }
`
